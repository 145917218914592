.team-members{
    margin: 100px 0px 300px 0px;
}
.team-members .team-item{
    padding: 20px;
}
.team-members .grayscale{
    filter: grayscale();
    opacity: 0.5;
}
.team-members .image{
    width: 100%;
    height: 500px;
}
.team-members .image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.team-members .person-details {
    margin-top: 20px;
}
.team-members .person-details h1{   
    color: #fff;
    font-size: 30px;
}
.team-members .person-details h6{
    font-size: 20px;
    color: #adadad;
}
.team-members .linkedin-icon{
    height: 50px;
    width: 70px;
    background-color: #ffff;
    border-radius: 10px;
    font-weight: 300;
    padding: 10px;
    margin-top: 20px;
    text-align: center;
}
.team-members .linkedin-icon .icon{
    text-align: center;
    margin: auto;
    color: #0077B5;
    font-size: 30px;
}

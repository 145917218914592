/* .breadcrumb .breadcrumb-title{
    margin: auto;
    width: 60%;
    text-align: center;
}
.breadcrumb .breadcrumb-title p{
    color: #fff;
}
.breadcrumb .breadcrumb-title{
    padding: 100px 0px;
}
.breadcrumb .breadcrumb-title h1{
    font-family: var(--graphik-font);
    font-weight: 600;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 18%, rgba(0,133,255,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    position: relative;
    color: #fff;
    font-size: 100px;
}
.breadcrumb .breadcrumb-title-outline h1 {
    -webkit-text-stroke: 1px #ffffff33; 
    color: transparent; 
    background-color: transparent;
    font-size: 200px;
    position: absolute;
    top: 15%;
    right: 50%;
    transform: translateX(50%);
} */


.blog-section{
    margin-bottom: 100px;
}
.blog-section a{
    text-decoration: none;
    color: #fff;
}
.blog-section .blog-large{
   background: none !important;
   border: none !important;
   padding-bottom: 100px;
}
.blog-section .blog-item {
    margin-bottom: 50px;
    padding: 20px;
    border-radius: 30px;
    border: 1px solid #027EF0;
    background: rgb(8,9,11);
    background: linear-gradient(144deg, rgba(8,9,11,1) 35%, rgba(7,30,51,1) 85%, rgba(7,43,79,1) 90%, rgba(4,66,122,1) 100%);    
}
.blog-section .blog-large {
    margin-bottom: 50px;
    border-radius: 30px;
    border: 1px solid #027EF0;
    background: rgb(8,9,11);
    background: linear-gradient(144deg, rgba(8,9,11,1) 35%, rgba(7,30,51,1) 85%, rgba(7,43,79,1) 90%, rgba(4,66,122,1) 100%);    
}
.blog-section .blog-large .blog-image{
    width: 100%;
    height: 600px;
    position: relative;
}
.blog-section .blog-item .blog-image{
    width: 100%;
    height: 270px;
    position: relative;
    filter: grayscale();
}
.blog-section .blog-image img{
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.blog-section  .date{
    position: absolute;
    right: 2%;
    top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #027EF0;
    background-color: #2c2c2cd1;
    width: 150px;
    height: 50px;
    border-radius: 50px;
}
.blog-section .arrow-shape-right{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #027EF0;
    background-color: #2c2c2cd1;
    width: 150px;
    height: 50px;
    border-radius: 50px;
} 
.blog-section .arrow-shape-right img{
    transform: rotate(180deg);
}
.blog-section .blog-large .blog-image .arrow-shape{
    position: absolute;
    left: 2%;
    top:3%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #027EF0;
    background-color: #2c2c2cd1;
    width: 150px;
    height: 50px;
    border-radius: 50px;
}
.blog-section .blog-large .blog-image .arrow-shape img{
    color: #fff;
    max-width: 60%;
    object-fit: contain;
}
.blog-section .blog-image .date h6{
    margin-bottom: 0px;
    color: #fff;
    padding: 10px;
}
.blog-section .blog-large .blog-content .blog-title h1{
    font-family: 'Inter', sans-serif;
    color: #fff;
    font-size: 35px ;
    font-weight: 400 ;
    text-transform: capitalize;
    margin: 20px 0px;
}
.blog-section .blog-item .blog-content .blog-title h1{
    font-family: 'Inter', sans-serif;
    color: #fff;
    font-size: 20px;
    font-weight: 400 ;
    text-transform: capitalize;
    margin: 20px 0px;
}
.blog-section .blog-content .blog-title p{
    color: #bfbfbf;
}
.blog-section .sec-title h1{
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    margin: 50px 0px 30px 0px;
    color: #fff;
    font-weight: 600;
}
.blog-section .blog-large .share button{
    height: 50px;
    width: 150px;
    border-radius: 50px;
    background-color: transparent;
    border: 2px solid #027EF0;
    color: #fff;
}
.blog-section .blog-large .blog-content .blog-title h1{
    margin: 0px;
}
.blog-section .blog-large .blog-content .blog-title{
    margin-top:40px;
}

/* blog overview page styles */
.blog-overview-page{
    padding: 100px 0px;
}
.blog-overview-page .blog-title{
    margin-top: 30px;
}
.blog-overview-page .share{
    justify-content: end
}
.blog-overview-page .blog-overview-content p{
    color: #fff;
    font-size: 18px;
    max-width: 60%;
}
.blog-item .blog-content .read-more h6{
    color: #fff;
    text-decoration: none;
}
.blog-large .read-more a{
    color: #fff;
    text-decoration: none;
}
.blog-large .read-more h6{
    margin-bottom: 0px;
}
.blog-overview-page .blog-overview-content p{
    max-width: 100% !important;
    color: var(--light-color);
}
.blog-overview-page .blog-title h1{
    text-align: left;
}

.blog-overview-page  .blog-large{
    margin-bottom: 20px ;
    padding-bottom: 50px;
}


/* share modal styles */
.share-modal{
    max-width: 500px !important;
}
.share-modal .social-media-icons{
    display: flex;
    padding-top: 20px;
}
.share-modal .social-media-icons .icon img{
    height: 50px;
    width: 50px;
    margin-right: 35px;
}


/* responsive */



@media screen and (min-device-width:320px) and (max-device-width: 480px){
    .blog-section .blog-large .blog-image{
        height: 200px;
    }
    .blog-section .date{
        width: 100px;
        height: 30px;
    }
    .blog-section .blog-image .date h6{
        font-size: 10px;
    }
    .blog-section .blog-large .blog-content .blog-title h1{
        font-size: 20px;
        margin-bottom: 20px;
    }
    .blog-section .arrow-shape-right{
        width: 100px;
        height: 30px;
    }
    .blog-section .arrow-shape-right h6{
        font-size: 10px;
    }
    .blog-section .blog-large{
        margin-bottom: 20px;
    }
    .blog-section .blog-large {
        padding-bottom: 20px;
    }
    .blog-section .sec-title h1{
        padding: 10px 5px;
        font-size: 30px;
    }
    .blog-section .blog-item .blog-image{
        height: 200px;
    }
    .blog-section .blog-item .blog-content .blog-title h1{
        font-size: 15px;
    }
    .blog-item .blog-content .read-more h6{
        text-decoration: underline;
        font-size: 13px;
    }
    .blog-section .blog-item{
        margin-bottom: 30px;
        padding: 15px;
    }
    .blog-overview-page{
        padding: 50px 0px;
    }
    .blog-section .blog-large .blog-image .arrow-shape{
        width: 80px;
        height: 30px;
    }
    .blog-section .blog-large .blog-image .arrow-shape h6{
        font-size: 10px;
    }
    .blog-section .blog-large .share button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 35px;
        font-size: 15px;
    }
    .career-modal .modal-title{
        font-size: 20px !important;
    }
    .share-modal .social-media-icons .icon img {
        height: 30px;
        width: 30px;
        margin-right: 15px;
    }
    .share-modal .social-media-icons{
        justify-content: space-evenly;
    }
    .career-modal .modal-body{
        padding: 10px 20px;
    }
    .career-modal .modal-header{
        padding: 10px 20px; 
    }
    .blog-overview-page .blog-overview-content p{
        font-size: 13px;
    }
    .blog-overview-page .blog-large{
        margin-bottom: 10px;
    }
}


@media screen and (min-device-width:481px) and (max-device-width: 768px){
    .blog-section .blog-large .blog-image{
        height: 250px;
    }
    .blog-section .date{
        width: 110px;
        height: 35px;
    }
    .blog-section .blog-image .date h6{
        font-size: 12px;
    }
    .blog-section .blog-large .blog-content .blog-title h1{
        font-size: 25px;
        margin-bottom: 20px;
    }
    .blog-section .arrow-shape-right{
        width: 110px;
        height: 35px;
    }
    .blog-section .arrow-shape-right h6{
        font-size: 12px;
    }
    .blog-section .blog-large{
        margin-bottom: 20px;
    }
    .blog-section .blog-large {
        padding-bottom: 20px;
    }
    .blog-section .sec-title h1{
        padding: 10px 5px;
        font-size: 30px;
    }
    .blog-section .blog-item .blog-image{
        height: 220px;
    }
    .blog-section .blog-item .blog-content .blog-title h1{
        font-size: 18px;
    }
    .blog-item .blog-content .read-more h6{
        text-decoration: underline;
        font-size: 15px;
    }
    .blog-section .blog-item{
        margin-bottom: 30px;
        padding: 15px;
    }
    .blog-overview-page{
        padding: 50px 0px;
    }
    .blog-section .blog-large .blog-image .arrow-shape{
        width: 90px;
        height: 35px;
    }
    .blog-section .blog-large .blog-image .arrow-shape h6{
        font-size: 12px;
    }
    .blog-section .blog-large .share button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 40px;
        font-size: 17px;
    }
    .career-modal .modal-title{
        font-size: 20px !important;
    }
    .share-modal .social-media-icons .icon img {
        height: 35px;
        width: 35px;
        margin-right: 15px;
    }
    .modal-dialog-scrollable .modal-content {
        max-height: 100%;
        width: 90%;
        margin: auto;
    }
    .share-modal .social-media-icons{
        justify-content: space-evenly;
    }
    .career-modal .modal-body{
        padding: 10px 20px;
    }
    .career-modal .modal-header{
        padding: 10px 20px; 
    }
    .blog-overview-page .blog-overview-content p{
        font-size: 15px;
    }
    .blog-overview-page .blog-large{
        margin-bottom: 10px;
    }
}


@media screen and (min-device-width:769px) and (max-device-width: 1024px){
    .blog-section .blog-large .blog-image{
        height: 350px ;
    }
    .blog-section .date{
        width: 120px;
        height: 40px;
    }
    .blog-section .blog-image .date h6{
        font-size: 15px;
    }
    .blog-section .blog-large .blog-content .blog-title h1{
        font-size: 28px;
        margin-bottom: 20px;
    }
    .blog-section .arrow-shape-right{
        width: 140px;
        height: 40px;
    }
    .blog-section .arrow-shape-right h6{
        font-size: 15px;
    }
    .blog-section .blog-large{
        margin-bottom: 20px;
    }
    .blog-section .blog-large {
        padding-bottom: 20px;
    }
    .blog-section .sec-title h1{
        padding: 10px 5px;
        font-size: 30px;
    }
    .blog-section .blog-item .blog-image{
        height: 300px;
    }
    .blog-section .blog-item .blog-content .blog-title h1{
        font-size: 20px;
    }
    .blog-item .blog-content .read-more h6{
        text-decoration: underline;
        font-size: 18px;
    }
    .blog-section .blog-item{
        margin-bottom: 50px;
        padding: 15px;
    }
    .blog-overview-page{
        padding: 50px 0px;
    }
    .blog-section .blog-large .blog-image .arrow-shape{
        width: 120px;
        height: 40px;
    }
    .blog-section .blog-large .blog-image .arrow-shape h6{
        font-size: 15px;
    }
    .blog-section .blog-large .share button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 40px;
        font-size: 18px;
    }
    .career-modal .modal-title{
        font-size: 20px !important;
    }
    .share-modal .social-media-icons .icon img {
        height: 35px;
        width: 35px;
        margin-right: 15px;
    }
    .modal-dialog-scrollable .modal-content {
        max-height: 100%;
        width: 90%;
        margin: auto;
    }
    .share-modal .social-media-icons{
        justify-content: space-evenly;
    }
    .career-modal .modal-body{
        padding: 10px 20px;
    }
    .career-modal .modal-header{
        padding: 10px 20px; 
    }
    .blog-overview-page .blog-overview-content p{
        font-size: 18px;
    }
    .blog-overview-page .blog-large{
        margin-bottom: 10px;
    }
}
/* Footer Styles */
.footer-content{
    padding: 50px 0px 0px 0px;
    background-image: url("../Resources//service-page-cta-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 30px;
}
.footer-content .logo img{
    width: 220px;
}
.footer-content .social-media .icon a{
    color: #0000;
}
.footer-content .social-media .icon:hover{
    background-color: #000;
    color: #fff !important;
}
.footer-content .social-media .icon:hover a{
    background-color: #000;
    color: #fff !important;
}
.footer-content .social-media .icon{
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.footer-content .social-media{
    margin-top: 30px;
    display: flex;
}
.footer-content .footer-menu{
    display: flex;
    justify-content: space-evenly;

}
.footer-content .footer-menu .menu-items ul{
    list-style-type: none;
    padding-left: 0px;
}
.footer-content .footer-menu .menu-items ul li{
    margin-bottom: 10px;
    font-size: 15px;
    font-family: 'Gilroy-Regular', sans-serif;
    color: #7e7e7e;
}
.footer-content .footer-menu .menu-items ul li:hover a{
    color: var(--primary-color);
    font-weight: 500;
}
.footer-content .footer-menu .menu-items ul li a{
    text-decoration: none;
    margin-bottom: 15px;
    font-size: 15px;
    font-family: 'Gilroy-Regular', sans-serif;
    color: #7e7e7e;
}
.footer-content .footer-menu .menu-items h1{
    margin-bottom: 20px;
    font-size: 17px;
    font-family: 'Gilroy-Regular', sans-serif;
    color: #000;
    font-weight: 600;
}
.footer-content .certificates {
    margin-top: 30px;
    display: flex;
}
.footer-content .certificates .image{
    width: 80px;
    height: 80px;
    margin-right: 20px;
}
.footer-content .certificates .image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.footer-bottom .copyright{
    margin-top: 30px;
}
.footer-bottom .copyright h6{
    border-top: 1px solid #bebebe;
    padding: 10px 0px;
    text-align: center;
}





/* responsive footer codes */


@media screen and (min-device-width:320px) and (max-device-width: 1024px){
    .footer-content .footer-menu{
        justify-content: left;
        margin-top: 30px;
    }
}